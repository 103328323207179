<template>
  <div>
    <el-dialog
      :title="con.id ? '编辑数据' : '添加数据'"
      :visible.sync="dialogVisible"
      :before-close="outClose"
      :close-on-click-modal="false"
      width="800px"
    >
      <div class="from" style="height:580px">
        <div class="list-data">
          <!-- <h3>基本信息</h3> -->
          <div class="list-dialog">
            <div class="list">
              <p style="margin-bottom: 10px;">
                公司名称
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.name" placeholder="请输入公司名称"></el-input>
            </div>

            <div class="list">
              <p style="margin-bottom: 10px;">
                公司地址
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.address" placeholder="请输入公司地址"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list">
              <p style="margin-bottom: 10px;">
                手机号码
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.phone" placeholder="请输入手机号码"></el-input>
            </div>

            <div class="list">
              <p style="margin-bottom: 10px;">
                公司电话
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.gs_phone" placeholder="请输入公司电话"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list">
              <p style="margin-bottom: 10px;">
                微信号码
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.vx_code" placeholder="请输入微信号码"></el-input>
            </div>

            <div class="list">
              <p style="margin-bottom: 10px;">
                经营范围
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.fanwei" placeholder="请输入经营范围"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list">
              <p style="margin-bottom: 10px;">
                阿里号
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.al_code" placeholder="请输入快手号"></el-input>
            </div>
            <div class="list">
              <p style="margin-bottom: 10px;">
                京东号
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.jdh_code" placeholder="请输入淘宝号"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list">
              <p style="margin-bottom: 10px;">
                快手号
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.ks_code" placeholder="请输入快手号"></el-input>
            </div>
            <div class="list">
              <p style="margin-bottom: 10px;">
                淘宝号
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.tb_code" placeholder="请输入淘宝号"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width:50%">
              <p style="margin-bottom: 10px;">
                公司分类
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <!-- <el-input v-model="con.projectStatus" placeholder="请输入产品分类"></el-input> -->
              <el-select style="width:100%" size="medium" v-model="con.type" placeholder="请选择公司分类">
                <el-option
                  v-for="item in productList"
                  :key="item.title"
                  :label="item.title"
                  :value="item.title"
                ></el-option>
              </el-select>
            </div>

            <div class="list">
              <p style="margin-bottom: 10px;">
                抖音号
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.dy_code" placeholder="请输入抖音号"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list">
              <p style="margin-bottom: 10px;">
                初始地
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.start" placeholder="请输入初始地"></el-input>
            </div>

            <div class="list">
              <p style="margin-bottom: 10px;">
                到达地
                <span style="color: red;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.arrive" placeholder="请输入到达地"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                网站链接
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.url" placeholder="请输入网站链接"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">
                排序
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>
              <el-input size="medium" v-model="con.sortOrder" placeholder="数字越大越靠前"></el-input>
            </div>
          </div>

          <div class="list-dialog">
            <div class="list">
              <p style="margin-bottom: 10px;">
                营业执照
                <span style="color: #ccc;margin-left: 0px;font-weight: 500;">*</span>
              </p>

              <div class="itemIms" v-if="con.imgs">
                <div class="img_list">
                  <img :src="con.imgs" alt />
                  <p class="el-icon-delete-solid" @click="logoRemove()"></p>
                </div>
              </div>
              <!-- <el-input v-model="con.projectStatus" placeholder="请输入产品分类"></el-input> -->
              <el-upload
                action="/upload"
                ref="logoImage"
                list-type="picture-card"
                :headers="uploadHeaders"
                :on-remove="logoRemove"
                :on-success="logoUploadSuccess"
                :auto-upload="true"
                :limit="1"
                v-if="!con.imgs"
              >
                <i class="el-icon-upload"></i>
              </el-upload>
            </div>
          </div>
          <!-- <div class="list-dialog">
            <div class="list" style="width:100%">
              <p style="margin-bottom: 10px;">产品详情</p>
              <vue-editor v-model="con.details" style="height: 30vh" />
            </div>
          </div> -->
        </div>
      </div>

      <template #footer>
        <div type="flex" slot="footer" class="dialog-footer">
          <el-button type="info" @click="outClose()">取 消</el-button>
          <el-button type="primary" @click="submit()">确 定</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
// import { getList } from "@/api/mysql";
import { addProduct, editProduct } from "@/api/product";
import { getList as getTypeList } from "@/api/download";
export default {
  props: {
    con: {
      type: Object,
      default: () => {}
    },
    mapList: {
      type: Array,
      default: () => []
    },
    groupList: {
      type: Array,
      default: () => []
    },
    dialogVisible: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      runtimeCodeList: [],
      runtimeStatusList: [],
      productList: [],
      dataBaseList: [],
      total: 0,
      logoHide: false,
      uploadHeaders: {
        Authorization: "Bearer" + " " + sessionStorage.getItem("access_token")
      }
    };
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        for (let key in this.con) {
          this.con[key] = "";
          this.con.map_list = [];
        }
      } else {
        getTypeList(1,100).then(res => {
          this.productList = res.data
        })
      }
    }
  },
  created() {
    // this.getList();
  },
  methods: {
    logoUploadSuccess(res) {
      this.$refs.logoImage.clearFiles();
      if (res.code == 200) {
        this.con.imgs = 'http://api.xn--114-st1hu08g.com/' + res.data;
      } else {
        this.$message.error(res.msg);
      }
      this.$forceUpdate();
    },
    
    logoRemove() {
      this.con.imgs = "";
      this.$forceUpdate();
    },

    submit() {
      if (this.con.id) {
        this.edit();
      } else {
        this.add();
      }
    },
    async add() {
      this.con.map_list = JSON.stringify(this.con.map_list);
      await addProduct(this.con).then(res => {
        if (res.code == 200) {
          this.$message.success(res.msg);
          this.$emit("outClose");
        }
      });
    },
    async edit() {
      this.con.map_list = JSON.stringify(this.con.map_list);
      await editProduct(this.con.id, this.con).then(res => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.$emit("outClose");
        }
      });
    },
    outClose() {
      this.$emit("outClose");
    }
  }
};
</script>

<style lang="less" scoped>
/deep/ .logo_hide .el-upload--picture-card {
  display: none !important;
}
/deep/ .el-checkbox.is-bordered.el-checkbox--medium {
  margin-right: 0px;
}
/deep/ .el-dialog__body {
  padding: 0px 20px;
}
/deep/ .el-dialog__header {
  padding: 10px 10px 10px;
  border-bottom: 1px solid #f5f6f7;
}
/deep/ .el-dialog__headerbtn {
  top: 15px;
}

/deep/ .el-upload--picture-card {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
  margin-left: 5;
}

/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 110px !important;
  height: 110px !important;
  line-height: 110px !important;
}

/deep/ .item .list .el-input[data-v-3f61525b],
.item .list .el-textarea[data-v-3f61525b] {
  width: 300px !important;
  margin-right: 20px;
}

/deep/ .avatar {
  width: 100%;
  height: 100%;
  display: block;
}

/* 弹出框居中显示 */
/deep/ .el-dialog {
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0px !important;
}
/* 弹出框超出部分滑动 */
/deep/ .el-dialog__body {
  overflow: hidden;
  overflow-y: auto;
}
/deep/ .el-radio {
  margin-right: 0px;
}

/deep/ .el-select .el-input {
  width: 100%;
}
.list-data {
  width: 100%;
  margin-top: 20px;
  h3 {
    width: 100%;
    height: 45px;
    line-height: 45px;
    background: #f57a3d;
    color: #fff;
    border-radius: 3px;
    text-indent: 10px;
    font-size: 15px;
    margin-bottom: 20px;
  }
  .list-dialog {
    width: 95%;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    .list {
      width: 350px;
      margin-bottom: 5px;
      .el-input {
        width: 100%;
      }
      .el-date-picker {
        width: 100%;
      }
    }
  }
}

.up_list {
  width: 95%;
  display: flex;
  margin: auto;
  margin-bottom: 20px;
  p {
    display: flex;
    margin-right: 20px;
    span {
      width: 10px;
      font-size: 15px;
      color: #000;
      opacity: 0.9;
      display: block;
    }
  }
}
.itemIms {
  width: auto;
  display: flex;
  .img_list {
    width: 110px;
    height: 110px;
    margin-right: 10px;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
    }
    p {
      position: absolute;
      width: 100%;
      height: 100%;
      line-height: 110px;
      background: rgba(0, 0, 0, 0.8);
      top: 0;
      color: #fff;
      text-align: center;
      cursor: pointer;
      opacity: 0;
      transition: 0.3s;
      border-radius: 5px;
      text-align: center;
      display: block;
      font-size: 20px;
    }
  }
  .img_list:hover p {
    opacity: 1;
  }
}
</style>