<template>
  <div>
    <div class="nav">
      <h1>资料管理/产品中心</h1>
    </div>
    <div class="search">
      <div class="left">
        <div class="box">
          <el-input
            placeholder="请输入搜索的公司名字或公司营业范围"
            style="width:500px"
            v-model="datas"
            clearable
            class="input-with-select"
          >
            <template slot="prepend">公司信息</template>
          </el-input>
          <el-button type="primary"  @click="getList()">搜索数据</el-button>
        </div>
      </div>
      <div class="right">
        <el-button type="primary" @click="saveProduct()">新增数据</el-button>
      </div>
    </div>

    <div class="result">
      <span></span>
      <p>查询结果</p>
    </div>

    <div class="table">
      <el-table :data="tableData" style="width: 100%" header-cell-style="background: #f5f7fa;">
        <el-table-column align="center" prop="name" width="150" :show-overflow-tooltip="true" label="公司名称" fixed="left"></el-table-column>
        <el-table-column align="center" prop="address" width="260" label="公司地址"></el-table-column>
        <el-table-column align="center" prop="phone" width="150" :show-overflow-tooltip="true" label="手机号码"></el-table-column>
        <el-table-column align="center" prop="gs_phone" width="150" :show-overflow-tooltip="true" label="公司电话"></el-table-column>
        <el-table-column align="center" prop="vx_code" width="150" :show-overflow-tooltip="true" label="微信号码"></el-table-column>
        <el-table-column align="center" prop="fanwei" width="150" :show-overflow-tooltip="true" label="经营范围"></el-table-column>
        <el-table-column align="center" prop="dy_code" :show-overflow-tooltip="true" label="抖音号"></el-table-column>
        <el-table-column align="center" prop="ks_code" width="150" :show-overflow-tooltip="true" label="快手号"></el-table-column>
        <el-table-column align="center" prop="tb_code" :show-overflow-tooltip="true" label="淘宝号"></el-table-column>
        <el-table-column align="center" prop="sortOrder" label="排序" width="100"></el-table-column>
        <el-table-column align="center" prop="createtime" label="创建时间" width="200"></el-table-column>
        <el-table-column align="center" width="150" prop="address" label="是否置顶" fixed="right">
          <template slot-scope="scope">
            <el-switch
              @change="topChange(scope.row.id,$event)"
              v-model="scope.row.is_top"
              :active-value="1"
              :inactive-value="0"
              >
            </el-switch>
          </template>
        </el-table-column>
        
        <el-table-column align="center" width="150" prop="address" label="显示/隐藏" fixed="right">
          <template slot-scope="scope">
            <el-switch
              @change="switchChange(scope.row.id,$event)"
              v-model="scope.row.is_show"
              :active-value="1"
              :inactive-value="0"
              >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="address" label="操作" fixed="right">
          <template slot-scope="scope">
            <div class="flex">
              <el-tooltip
                class="item"
                @click.native="editNews(scope.row)"
                effect="dark"
                content="编辑产品"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-edit"></i>
                </p>
              </el-tooltip>

              <el-tooltip
                class="item"
                @click.native="delList(scope.row.id)"
                effect="dark"
                content="删除产品"
                placement="top"
              >
                <p class="table-btn">
                  <i class="el-icon-delete-solid"></i>
                </p>
              </el-tooltip>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="pageChange"
          :page-sizes="[10, 50, 100, 150, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
    </div>
    <operationProduct :dialogVisible="dialogVisible" :con="con" @outClose="dialogVisible = false" />
  </div>
</template>

<script>
import operationProduct from "./components/operationProduct.vue";
import { getList, delProduct,editProduct } from "@/api/product";
export default {
  components: {
    operationProduct
  },
  inject: ['reload'],
  data() {
    return {
      total: 0,
      pageNum: 1,
      pageSize: 10,
      item: [],
      tableData: [],
      classifyList: [],
      dialogVisible: false,
      datas: "",
      con: {
        map_list: []
      }
    };
  },
  created() {
    this.getList();
  },
  watch: {
    dialogVisible(data) {
      if (!data) {
        this.getList();
      }
    }
  },
  methods: {
    switchChange(e,val) {
      editProduct(e, {
        is_show: val
      }).then(res => {
        if (res.code == 200) {
          this.$message.success("编辑成功");
          this.$emit("outClose");
        }
      });
    },
    topChange(e,val) {
      editProduct(e, {
        is_top: val
      }).then(res => {
        if (res.code == 200) {
          this.$message.success("置顶编辑成功");
          this.$emit("outClose");
        }
      });
    },
    saveProduct() {
      this.dialogVisible = true;
    },
    handleSizeChange(e) {
      this.pageSize = e;
      this.getList();
    },
    pageChange(e) {
      this.pageNum = e;
      this.getList();
    },

    editNews(data) {
      this.con = JSON.parse(JSON.stringify(data));
      this.dialogVisible = true;
    },
    delList(id) {
      let that = this;
      this.$confirm("您正在删除产品, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          delProduct(id).then(res => {
            console.log(res);
            that.dialogVisible = false;
            that.$message.success("删除成功");
            that.reload();
          });
        })
        .catch(() => {
          that.$message.info("删除失败");
        });
    },
    async getList() {
      await getList(this.pageNum,this.pageSize,this.datas).then(res => {
        this.tableData = res.data.list;
        this.total = res.data.total
      });
    }
  }
};
</script>

<style lang="less" scoped>
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  .left {
    width: 60%;
    display: flex;
    align-items: flex-end;
    .box {
      display: flex;
      /deep/ .el-input {
        margin-right: 20px;
        //   margin-left: 20px;
      }
    }
    /deep/ .el-select {
      width: 250px;
      margin-right: 20px;
    }
    .el-button {
      margin-left: 20px;
    }
    .input-with-select {
      width: 600px;
    }
  }
  .right {
    // width: 40%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    .box {
      display: flex;
      align-items: center;
    }
  }
}

.table {
  margin-top: 20px;
  .shop {
    display: flex;
    align-items: center;
    img {
      width: 40px;
      height: 40px;
      margin-right: 5px;
    }
    p {
      position: relative;
    }
  }
}
</style>